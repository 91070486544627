import * as React from "react"
import logo from "../images/logo.svg"
import { SiInstagram, SiYoutube, SiLinkedin } from "react-icons/si"
import { openConsentManager } from "@segment/consent-manager"
import LazyLoad from "react-lazyload"
const NETLIFY_BRANCH = process.env.TEST_VAR

/*Track branch segment for split test*/
if (typeof window !== "undefined") {
  console.log(NETLIFY_BRANCH)
  /*Segment*/
  window.analytics.track("pageview", {
    Branch: process.env.TEST_VAR,
  })
}

const Footer = ({ siteTitle }) => (
  <footer>
    <section>
      <div className="bg-gray-800 px-5 py-5 mx-auto">
        <div className="flex mx-3 justify-center items-center text-center">
          <div className="w-full lg:w-1/5 px-3 mb-2 lg:mb-4">
            <a href="/">
              <LazyLoad once={true} offset={500}>
                <img
                  className="inline-block mx-auto lg:mx-0 text-3xl font-semibold leading-none"
                  src={logo}
                  alt="Logo"
                  width={150}
                  height={40}
                />
              </LazyLoad>
            </a>
          </div>
        </div>

        <div className="text-blueGray-300 text-xs md:text-sm font-normal mx-auto">
          <div className="p-2 flex justify-center flex-col sm:flex-row gap-1 sm:gap-4 text-sm text-center ">
            <a className="font-normal" href="/datenschutz">
              Datenschutz
            </a>
            <a className="font-normal" href="/impressum">
              Impressum
            </a>
            <button className="font-normal" onClick={openConsentManager}>
              Cookie Einstellungen
            </button>
          </div>
        </div>
        {/* social - start */}
        <div className="p-2 flex gap-4 justify-center">
          <a
            href="https://www.instagram.com/aroundsec/"
            target="_blank"
            rel="noreferrer"
            className="text-gray-300 hover:text-gray-500 active:text-gray-600 transition duration-100"
          >
            <SiInstagram size={20} />
          </a>
          <a
            href="https://www.youtube.com/channel/UC3y7LO_xGtxLjFgo25DK99g"
            target="_blank"
            rel="noreferrer"
            className="text-gray-300 hover:text-gray-500 active:text-gray-600 transition duration-100"
          >
            <SiYoutube size={20} />
          </a>
          <a
            href="https://www.linkedin.com/company/aroundsec/"
            target="_blank"
            rel="noreferrer"
            className="text-gray-300 hover:text-gray-500 active:text-gray-600 transition duration-100"
          >
            <SiLinkedin size={20} />
          </a>
        </div>
        {/* social - end */}
        <div className="pt-2 text-xs md:text-sm font-normal text-center text-blueGray-400">
          © 2024 - AROUND IT-Security GmbH
        </div>
      </div>
    </section>
  </footer>
)

export default Footer
